/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
// @import url('https://fonts.cdnfonts.com/css/avenir');

body {
  // font-family: 'Avenir', sans-serif;
  font-family: 'DM Sans', sans-serif;
  @apply text-lg;
}

.form-control {
  @apply block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-blue-100 appearance-none focus:outline-none;
  &.control-dark {
    @apply border-blue-900;
  }
  +label {
    @apply font-normal absolute text-sm text-black duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0];
  }
  &.form-control-outline {
    @apply px-2.5 pb-2.5 pt-4 border-2 rounded-lg;
    +label {
      @apply -translate-y-4 top-2 z-10 bg-white px-2 left-1;
    }
  }
  &:disabled {
    @apply bg-gray-50 border-gray-300;
    +label {
      @apply text-gray-400 bg-transparent;
    }
  }
}

.btn {
  @apply font-medium rounded-lg text-lg px-5 py-2.5;
  &.btn-block {
    @apply w-full;
  }

  &.btn-primary {
    @apply text-white bg-blue-100 hover:bg-sky-400 focus:ring-4 focus:ring-sky-300 focus:outline-none;

    &-outline {
      @apply bg-transparent text-blue-100 border border-blue-100 hover:bg-blue-100 hover:text-white focus:ring-4 focus:ring-sky-300 focus:outline-none;
    }

    &:disabled {
      @apply bg-blue-100/60 cursor-not-allowed;
    }
  }
  &.btn-secondary {
    @apply text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-400 focus:outline-none;

    &-outline {
      @apply bg-transparent text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white focus:ring-4 focus:ring-blue-400 focus:outline-none;
    }

    &:disabled {
      @apply bg-blue-500/60 cursor-not-allowed;
    }
  }

  &.btn-rounded {
    @apply rounded-full p-2.5 text-center inline-flex items-center;
  }
}

.card {
  /* @apply md:border md:border-gray-300 bg-white md:shadow-lg shadow-none rounded-lg md:p-10; */
  @apply p-4 w-full md:p-10 sm:max-w-md;
}

/* TABLE STYLES */
.searchbar {
  @apply w-full;

  &-input {
    @apply block p-2 pl-10 text-sm text-blue-900 bg-blue-900/[0.1] rounded-full w-full md:w-80 focus:ring-4 focus:outline-none focus:ring-blue-900/[0.05];
  }
}

.export-actions-btn {
  @apply ml-2 inline-flex items-center p-2 text-sm font-medium text-center text-blue-900 bg-white rounded-lg hover:bg-blue-900/[0.1] focus:ring-4 focus:outline-none focus:ring-blue-900/[0.05];
}

.table-wrapper {
  .table {
    @apply w-full text-left text-gray-400 text-base;

    .thead {
      @apply text-xs uppercase bg-blue-900;

      tr th {
        @apply px-6 py-3 cursor-pointer whitespace-nowrap;
      }
    }

    tbody {
      tr {
        &:hover {
          @apply hover:bg-blue-500/[0.15];
        }

        &:nth-child(even) {
          @apply bg-gray-100;
        }
      }
    }
  }
}

.pagination-container {
  @apply flex flex-col items-center;

  .pagination-actions {
    @apply inline-flex mt-2;

    .pagination-btn {
      @apply inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-100 hover:bg-sky-400 focus:ring-4 focus:ring-sky-300 focus:outline-none;

      &:disabled {
        @apply bg-blue-100/60 cursor-not-allowed;
      }

      &.prev-btn {
        @apply rounded-l-lg;
      }

      &.next-btn {
        @apply rounded-r-lg;
      }
    }
  }
}
